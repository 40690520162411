<template>
  <b-card>
    <div>
      <validation-observer ref="iframeValidation">
        <b-form class="auth-login-form mt-2" @submit.prevent>
          <!-- email -->
          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            :label="$t('iframe.name')"
          >
            <validation-provider name="iframe.name" rules="required">
              <b-form-input class="input-max-width" v-model="iframe.name" />
            </validation-provider>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            :label="$t('iframe.hash')"
          >
            <b-form-input
              class="input-max-width"
              v-model="iframe.hash"
              disabled
            />
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            :label="$t('iframe.html')"
          >
            <b-row ref="row">
              <!-- Cost -->
              <b-col md="3">
                <b-form-group label="URL" label-for="speedFrom">
                  <b-form-input
                    class="input-max-width"
                    v-model="iframe.html"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <!-- Remove Button -->
              <b-col lg="2" md="2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-primary"
                  class="mt-0 mt-md-2"
                  @click="copyText()"
                  v-if="$route.params.id"
                >
                  <!--<feather-icon icon="XIcon" class="mr-25" />-->
                  <span>{{ $t('iframe.copy') }}</span>
                </b-button>
              </b-col>
              <b-col lg="2" md="2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-primary"
                  class="mt-0 mt-md-2"
                  @click="previewItem()"
                  v-if="$route.params.id"
                >
                  <!--<feather-icon icon="XIcon" class="mr-25" />-->
                  <span>{{ $t('iframe.preview') }}</span>
                </b-button>
              </b-col>
              <b-col cols="10">
                <!--<hr />-->
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            :label="$t('iframe.url')"
          >
            <b-card>
              <div>
                <b-form
                  ref="form"
                  :style="{ height: trHeight }"
                  class="repeater-form"
                  @submit.prevent="repeateAgain"
                >
                  <!-- Row Loop -->
                  <b-row v-for="(item, index) in urls" :key="index" ref="row">
                    <!-- Cost -->
                    <b-col md="3">
                      <b-form-group label="URL" label-for="speedFrom">
                        <b-form-input
                          v-model="item.url"
                          type="text"
                          placeholder=""
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Remove Button -->
                    <b-col lg="2" md="4" class="mb-50">
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeItem(index)"
                      >
                        <!--<feather-icon icon="XIcon" class="mr-25" />-->
                        <span>{{ $t('delete') }}</span>
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <!--<hr />-->
                    </b-col>
                  </b-row>
                </b-form>
              </div>
              <div class=" mb-1">
                <br />
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateAgain"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{ $t('new_entry') }}</span>
                </b-button>
              </div>
            </b-card>
          </b-form-group>
          <advanced-table
            :tableColumns="tableColumns"
            dataAction="devices/GET_IFRAMES_DATA"
            :dataURLParams="{ id: $route.params.id }"
            :createRoute="{ name: 'iframe-create', params: $route.params.type }"
            ref="iframesTable"
            :hideSearch="true"
            :key="count"
            :hidePagination="false"
            :responsive="false"
          >
            <template #cell(device)="data">
              <model-select
                class="bordered"
                v-model="data.item.deviceId"
                :options="deviceOptions"
              />
            </template>
            <template #cell(type)="data">
              <b-form-select
                class="bordered"
                v-model="data.item.type"
                :options="typeOptions"
              />
            </template>
            <template #cell(duration)="data">
              <b-form-input
                id="demo-sb"
                min="1"
                max="100"
                v-model="data.item.duration"
                :value="data.item.duration"
              ></b-form-input>
            </template>
            <template #cell(history)="data">
              <b-form-input
                id="demo-sb"
                min="1"
                max="365"
                v-model="data.item.history"
                :value="data.item.history"
              ></b-form-input>
            </template>
            <template #cell(order)="data">
              <b-form-input
                id="demo-sb"
                min="1"
                max="100"
                v-model="data.item.order"
                :value="data.item.order"
              ></b-form-input>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="deleteIframe(data.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t('delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template></advanced-table
          >
        </b-form>
      </validation-observer>
      <div class=" margin">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="addIframe"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>{{ $t('new_entry') }}</span>
        </b-button>
      </div>
      <div class="">
        <b-button @click="saveIframe" variant="primary">{{
          $t('iframe.add_iframe')
        }}</b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BCard,
  BFormCheckbox,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSpinbutton,
  BFormSelect,
  BCol,
  BRow,
} from 'bootstrap-vue';
import AdvancedTable from '@/views/components/AdvancedTable.vue';
import i18n from '@/libs/i18n';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref } from '@vue/composition-api';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import iframeTypes from '@/views/settings/devices/types.json';
import { deviceType } from '@/types/deviceType';
import { dangerToast, successToast } from '@/utils/toast';
import { ModelSelect } from 'vue-search-select';

import 'vue-search-select/dist/VueSearchSelect.css';

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BCard,
    BFormCheckbox,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSpinbutton,
    AdvancedTable,
    ValidationProvider,
    ValidationObserver,
    ModelSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],

  data() {
    return {
      tableColumns: [
        { key: 'device', label: i18n.tc('iframe.location'), sortable: false },
        { key: 'type', label: i18n.tc('iframe.type'), sortable: false },
        { key: 'duration', label: i18n.tc('iframe.duration'), sortable: false },
        { key: 'history', label: i18n.tc('iframe.history'), sortable: false },
        { key: 'order', label: i18n.tc('iframe.order'), sortable: false },
        { key: 'actions', label: i18n.tc('actions'), sortable: false },
      ],
      selectedType: ref(null),
      typeOptions: {},
      selectedDevice: ref(null),
      devices: [],
      iframe: {},
      urls: [],
      deviceOptions: [],
      count: 0,
    };
  },
  mounted() {
    if (this.$route.params.type == 'speeding') {
      this.typeOptions = iframeTypes.speeding;
    } else if (this.$route.params.type == 'radar') {
      this.typeOptions = iframeTypes.radar;
    } else if (this.$route.params.type == 'cyclist') {
      this.typeOptions = iframeTypes.cyclist;
    } else if (this.$route.params.type == 'vehicles') {
      this.typeOptions = iframeTypes.vehicles;
    }
    this.getDevices();
    if (this.$route.params.id) {
      this.getIframe();
    }
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  methods: {
    previewItem() {
      var getUrl = window.location;
      var baseUrl = getUrl.protocol + '//' + getUrl.host + '/';
      window.open(baseUrl + 'iframe/locations/' + this.iframe.hash, '_blank');
    },
    async getIframe() {
      const response = await this.$store.dispatch(
        'devices/GET_IFRAME',
        this.$route.params.id,
      );
      this.iframe = response.data;
      this.urls = JSON.parse(this.iframe.urls_json);
    },
    // TODO TYPE
    async getDevices() {
      const response = await this.$store.dispatch('devices/GET_DEVICES', {
        type: this.$route.params.type,
      });
      this.devices = response.data;
      response.data.forEach(element => {
        this.deviceOptions.push({ value: element.id, text: element.name });
      });
    },
    async saveIframe() {
      const validation = await this.$refs.iframeValidation.validate();
      if (validation) {
        if (this.$route.params.id) {
          var getUrl = window.location;
          var baseUrl = getUrl.protocol + '//' + getUrl.host;
          this.iframe.html =
            '<iframe width="100%" height="900" frameborder="0" src="' +
            baseUrl +
            '/iframe/locations/' +
            this.iframe.hash +
            '"></iframe>';

          this.iframe.urls_json = JSON.stringify(this.urls);

          const response = await this.$store.dispatch('devices/UPDATE_IFRAME', {
            iframe: this.iframe,
            iframedata: this.$refs.iframesTable.data,
          });

          this.count++;

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('success'),
              icon: 'AlertTriangleIcon',
              variant: 'success',
              text: this.$t('iframe.iframe_created'),
            },
          });
        } else {
          this.iframe.hash =
            Math.random()
              .toString(36)
              .substring(2, 15) +
            Math.random()
              .toString(36)
              .substring(2, 15);
          var getUrl = window.location;
          var baseUrl = getUrl.protocol + '//' + getUrl.host;
          this.iframe.html =
            '<iframe width="100%" height="900" frameborder="0" src="' +
            baseUrl +
            '/iframe/locations/' +
            this.iframe.hash +
            '"></iframe>';
          this.iframe.urls_json = JSON.stringify(this.urls);
          this.iframe.deviceType = deviceType[this.$route.params.type];
          const response = await this.$store.dispatch('devices/ADD_IFRAME', {
            iframe: this.iframe,
          });
          this.$refs.iframesTable.data.forEach(element => {
            element.iframeId = response.data.generatedMaps[0].id;
          });

          const responseUpdate = await this.$store.dispatch(
            'devices/UPDATE_IFRAME',
            {
              iframe: this.iframe,
              iframedata: this.$refs.iframesTable.data,
            },
          );
          this.count++;

          if (response) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('success'),
                icon: 'AlertTriangleIcon',
                variant: 'success',
                text: this.$t('generalSettings.device_created'),
              },
            });
            this.$router.push({
              name: 'iframe-list',
              params: { type: this.$route.params.type },
            });
          }
        }
      }
    },
    copyText() {
      this.$copyText(this.iframe.html).then(
        e => {
          successToast(this.$toast, this.$t('text_copied'), this.$t(''));
        },
        e => {
          dangerToast(this.$toast, this.$t('failed_to_copy'), this.$t(''));
        },
      );
    },

    repeateAgain() {
      this.urls.push({});

      // this.$nextTick(() => {});
    },
    removeItem(index) {
      this.urls.splice(index, 1);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    addIframe() {
      this.$refs.iframesTable.data.push({
        id: null,
        type: this.typeOptions[0].text,
        duration: 5,
        history: 7,
        order: 0,
        iframeId: this.$route.params.id,
        deviceId: null,
        createdAt: null,
        updatedAt: null,
      });
    },
    async deleteIframe(index) {
      const response = await this.$store.dispatch(
        'devices/DELETE_IFRAME_DATA',
        index,
      );
      this.$refs.iframesTable.data.splice(index, 1);

      dangerToast(
        this.$toast,
        this.$t('deleted'),
        this.$t('iframe.iframe_deleted'),
      );
      this.count++;
    },
  },
  watch: {
    '$route.params.type': function(id) {
      setTimeout(() => {
        this.$refs.iframesTable.refresh();
      }, 50);
    },
  },
};
</script>

<style>
.input-max-width {
  max-width: 500px;
}
.repeater-form {
  transition: 0.25s height;
}
.margin {
  margin-bottom: 10px;
}
</style>
